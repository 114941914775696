





































































































































































import CopyStateTransactionTypeFilter from '@/components/copy-stake/CopyStateTransactionTypeFilter.vue';
import CopyStakeMixin from '@/mixins/copy-stake/CopyStakeMixin';
import { capitalization, textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { DataTableHeader } from 'vuetify';
import {
  BASE_CURRENCY,
  defaultDates,
  TStreamFormDates
} from '@/helpers/copyStakeHelpers';
import { mapGetters } from 'vuex';
import { PropType } from 'vue';
import {
  EStreamerTransactionType,
  TStreamTransaction,
  TStreamTransactionsPayload
} from '@/api/schema';

import {
  getStreamTransactions,
  downloadStreamCsvReport
} from '@/api/CopyStake';
import { getTransactionByHash } from '@/api/Transaction';
import { doDownload } from '@/helpers/download';
import formatDate from '@/helpers/formatDate';

type TCopyStakeTableState = {
  headers: Readonly<DataTableHeader[]>;
  loadingState: {
    csv: boolean;
    filter: boolean;
    query: boolean;
    date: boolean;
  };
  lastQuery: string;
  page: number;
  lastDate: string[];
  hasNext: boolean;
  selectValue: ESelectType;
  selectedType: EStreamerTransactionType;
  selectItems: Readonly<{ text: string; value: ESelectType }[]>;
  searchQuery: string;
  transactions: TStreamTransaction[];
};

enum ESelectType {
  OPERATOR_USER_ID = 'operatorUserId',
  COPIED_FROM_WALLET_HASH = 'copiedFromWalletHash',
  REFERENCE_TRANSACTION_ID = 'referenceTransactionId',
  HASH = 'hash'
}

export default {
  name: 'CopyStakeStatsTable',
  components: {
    CopyStateTransactionTypeFilter
  },
  mixins: [CopyStakeMixin],
  props: {
    dates: {
      type: Object as PropType<{ [key: string]: string }>,
      default: (): TStreamFormDates => defaultDates
    }
  },
  data(): TCopyStakeTableState {
    return {
      hasNext: false,
      page: 1,
      lastDate: [],
      lastQuery: undefined,
      transactions: [],
      loadingState: {
        csv: false,
        filter: false,
        query: false,
        date: false
      },
      selectedType: null,
      selectValue: ESelectType.OPERATOR_USER_ID,
      selectItems: Object.freeze([
        { text: 'User ID', value: ESelectType.OPERATOR_USER_ID },
        {
          text: 'Wallet ID Streamer',
          value: ESelectType.COPIED_FROM_WALLET_HASH
        },
        {
          text: 'Reference Transaction ID',
          value: ESelectType.REFERENCE_TRANSACTION_ID
        },
        { text: 'Hash', value: ESelectType.HASH }
      ]),
      searchQuery: undefined,
      headers: Object.freeze([
        {
          text: 'Created at',
          value: 'createdAt'
        },
        {
          text: 'Transaction Type',
          value: 'type'
        },
        {
          text: `Wallet ID Streamer`,
          value: 'streamerWallet'
        },
        {
          text: `User ID`,
          value: 'operatorUserId'
        },
        {
          text: `Amount, Based Currency`,
          value: 'amountInCurrency'
        },
        {
          text: `Amount, ${BASE_CURRENCY}`,
          value: 'amountInUSDT'
        },
        {
          text: 'Hash',
          value: 'hash'
        },
        { text: '', value: 'data-table-expand' }
      ])
    };
  },
  watch: {
    dates: {
      handler: 'updateData',
      deep: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    isLoading(): boolean {
      return Object.values(this.loadingState).some(Boolean);
    },
    paginationLength(): number {
      if (!this.transactions.length) return 0;

      return this.page + (this.hasNext ? 1 : 0);
    },
    formattedList(): any[] {
      return this.transactions.map((item: TStreamTransaction) => ({
        ...item,
        operatorUserId: item?.attributes?.OPERATOR_USER_ID,
        streamerWallet: item?.attributes?.COPIED_FROM_WALLET,
        amountInUSDT: this.formatValue(
          item?.attributes?.AMOUNT_USDT,
          BASE_CURRENCY
        ),
        amountInCurrency: this.formatValue(
          item?.attributes?.AMOUNT,
          item?.attributes?.CURRENCY
        ),
        createdAt: this.getDate(item?.createdAt),
        hash: item?.id,
        referenceId: item?.attributes?.REFERENCE_GA_ID
      }));
    },
    formPayload(): TStreamTransactionsPayload {
      return {
        [this.selectValue]: this.searchQuery,
        from: this.formatDate(this.dates.from),
        to: this.formatDate(this.lastDate[this.page] || this.dates.to, true),
        type: this.selectedType,
        size: 10,
        operatorId: this.operatorId
      };
    },
    isSearchByHash(): boolean {
      return this.selectValue === ESelectType.HASH;
    }
  },
  methods: {
    textOverflowMiddle,
    copyToClipBoard,
    capitalization,
    getDate(createdAt: string): string {
      return formatDate(
        createdAt.slice(0, createdAt.lastIndexOf('.')),
        'MM.dd.yy HH:mm',
        true,
        false
      );
    },
    changePage(page: number): void {
      const listLength = this.transactions?.length;
      const isFirst = page === 1;
      if (isFirst) {
        this.lastDate = [];
      }

      const lastDate = isFirst
        ? this.formatDate(this.dates.to, true)
        : this.lastDate[page] || this.transactions[listLength - 1].createdAt;

      this.loadData('date', lastDate).then(() => {
        this.page = page;
        this.lastDate[page] = lastDate;
      });
    },
    formatDate(date: string, endDay: boolean = false): string {
      if (date?.includes('T')) return date;

      if (endDay) return `${date}T23:59:59.000.000000000`;

      return `${date}T00:00:00.000.000000000`;
    },
    resetParams(): void {
      this.page = 1;
      this.lastDate = [];
      this.lastDate[this.page] = this.formatDate(this.dates.to, true);
      this.lastQuery = undefined;
    },
    resetTable(): void {
      this.transactions = [];
      this.hasNext = false;
    },
    clearHandler(): void {
      this.searchQuery = undefined;

      if (this.selectValue === ESelectType.HASH) {
        this.selectValue = ESelectType.OPERATOR_USER_ID;
      }

      if (this.lastQuery) {
        this.loadData('query');
      }
    },
    submitQuery(): void {
      this.loadData('query');
    },
    updateData(): void {
      this.resetParams();
      this.loadData();
    },
    async loadData(
      type: 'filter' | 'query' | 'date' = 'date',
      to: string = this.formPayload.to
    ): Promise<void> {
      if (this.isLoading || !this.operatorId) return;

      this.loadingState[type] = true;

      if (type !== 'date') {
        this.resetParams();
      }

      try {
        if (this.selectValue === ESelectType.HASH) {
          const transactionData = await getTransactionByHash({
            hash: this.searchQuery
          });
          this.transactions = [transactionData];
          this.hasNext = false;
        } else {
          const { transactions, hasNext } = await getStreamTransactions({
            ...this.formPayload,
            to
          });
          this.transactions = transactions || [];
          this.hasNext = hasNext;
        }
      } catch (e) {
        this.handleError(e);
        this.resetParams();
        this.resetTable();
      } finally {
        this.lastQuery = this.searchQuery;
        this.loadingState[type] = false;
      }
    },
    handlerOnFilterTypeChange(payload: EStreamerTransactionType): void {
      this.selectedType = payload;
      this.loadData('filter');
    },
    downloadCsv(): void {
      this.loadingState.csv = true;

      downloadStreamCsvReport({ ...this.formPayload, size: 10000 })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const date = Object.values(this.dates)
            .map((item: string) => item.replaceAll('-', '.'))
            .join('-');
          doDownload(url, `copy-stake-report[${date}].csv`);
        })
        .catch(this.handleError)
        .finally(() => {
          this.loadingState.csv = false;
        });
    }
  }
};
