import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import {
  OperatorTransactionsListCSVRequestParams,
  OperatorTransactionsListRequestParams,
  TransactionByHashRequestParams,
  TransactionsListResponse,
  TStreamTransaction,
  UserTransactionsListCSVRequestParams,
  UserTransactionsListRequestParams,
  UserTransactionsTotalAmountRequestParams
} from './schema';

/**
 * Get transactions list by operator.
 * @return Promise with transactions list.
 */
export async function getOperatorTransactionsList(
  params: OperatorTransactionsListRequestParams
): Promise<TransactionsListResponse> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/transactions/by-operator'), {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse): TransactionsListResponse => data);
}

/**
 * Get operator transactions list csv report.
 * @return Promise with transactions list csv report.
 */
export async function getOperatorTransactionsListCSVReport(
  params: OperatorTransactionsListCSVRequestParams
): Promise<any> {
  return http.get(
    buildUrl(`/api/v1/{operatorId}/transactions/by-operator/csv`),
    {
      params
    }
  );
}

/**
 * Get transactions list by user.
 * @return Promise with transactions list.
 */
export async function getUserTransactionsList(
  params: UserTransactionsListRequestParams
): Promise<TransactionsListResponse> {
  return http
    .get(buildUrl('/api/v1/{operatorId}/transactions/by-user'), {
      params,
      paramsSerializer: {
        indexes: null
      }
    })
    .then(({ data }: AxiosResponse): TransactionsListResponse => data);
}

/**
 * Get user transactions list csv report.
 * @return Promise with transactions list csv report.
 */
export async function getUserTransactionsListCSVReport(
  params: UserTransactionsListCSVRequestParams
): Promise<any> {
  return http.get(buildUrl(`/api/v1/{operatorId}/transactions/by-user/csv`), {
    params
  });
}

/**
 * Get user transactions total amount.
 * @return Promise with user transactions total amount.
 */
export async function getUserTransactionsTotalAmount(
  params: UserTransactionsTotalAmountRequestParams
): Promise<{ data: number }> {
  return http.get(
    buildUrl(`/api/v1/{operatorId}/transactions/by-user/total-amount`),
    {
      params
    }
  );
}

export const getTransactionByHash = (
  params: TransactionByHashRequestParams
): Promise<TStreamTransaction> => {
  return http
    .get('/api/v2/tx/by-hash', {
      params
    })
    .then(({ data }: AxiosResponse<TStreamTransaction>) => data);
};
