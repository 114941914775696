


























import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import CopyStakeStats from '@/views/CopyStake/CopyStakeStats/CopyStakeStats.vue';
import CopyStakeStatsTable from '@/views/CopyStake/CopyStakeStats/CopyStakeStatsTable.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { defaultDates, MIN_DATE } from '@/helpers/copyStakeHelpers';
import { getStreamStats } from '@/api/CopyStake';
import { TStreamerStat, TStreamerStats, DateRangeParams } from '@/api/schema';
import router from '@/router';

type TCopyStakeStatsState = {
  MIN_DATE: string;
  isLoadingStats: boolean;
  dates: DateRangeParams;
  uniqueStreamersCount: number | string;
  copyStakeGGR: number;
  copyStakeStats: TStreamerStat[];
};

export default {
  name: 'CopyStakeStatsView',
  components: {
    CopyStakeStats,
    CopyStakeStatsTable,
    VExtendedDataPicker
  },
  data(): TCopyStakeStatsState {
    return {
      MIN_DATE,
      isLoadingStats: true,
      dates: { ...defaultDates },
      uniqueStreamersCount: '-',
      copyStakeGGR: 0,
      copyStakeStats: []
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('FeatureFlags', ['features', 'loading']),
    ...mapState('app', ['isSuperAdmin']),
    isEnabledCopyStake(): boolean {
      return (
        this.features.find((feature) => feature.name === 'live_streamer')
          ?.enabled === true
      );
    }
  },
  watch: {
    operatorId: {
      handler(): void {
        this.fetchStakeStats();
      },
      immediate: true
    },
    dates: {
      handler(): void {
        this.fetchStakeStats();
      },
      immediate: true,
      deep: true
    },
    loading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        router.push('/');
      },
      immediate: true
    }
  },
  methods: {
    async fetchStakeStats(): Promise<void> {
      if (!this.operatorId) return;
      this.isLoadingStats = true;

      try {
        const { ggr, uniqueStreamersCount, data }: TStreamerStats =
          await getStreamStats(this.dates);

        const isDataValid = Array.isArray(data) && data.length;

        this.copyStakeGGR = ggr;
        this.uniqueStreamersCount = uniqueStreamersCount || 0;

        this.$set(this, 'copyStakeStats', isDataValid ? data : []);

        if (!isDataValid) {
          this.$toast.error('There is no data for the specified parameters');
        }
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.isLoadingStats = false;
      }
    }
  }
};
