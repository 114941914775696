import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import { mapGetters } from 'vuex';
import { VALUE_PLACEHOLDER } from '@/helpers/copyStakeHelpers';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isLoading(value: boolean): void {
      this.$emit('update:loading', value);
    },
    operatorId: {
      handler(): void {
        this.loadData();
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    isProcessing(): boolean {
      return this.isLoading || this.loading;
    }
  },
  methods: {
    handleError(error: AxiosError): void {
      errorToastMessage(error);
    },
    formatValue(v: string | number, currency: string = ''): string {
      if (v === VALUE_PLACEHOLDER || (!v && typeof v !== 'number'))
        return VALUE_PLACEHOLDER;

      try {
        return `${this.$options.filters.numeralSpaces(
          v,
          '0,0.[00]'
        )} ${currency}`.trim();
      } catch {
        return VALUE_PLACEHOLDER;
      }
    }
  }
};
